/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from 'react';
import { Header } from '../Header';
import './index.scss';
import { BookingFormContext } from '../../context/booking-form';
import { RouteComponentProps } from '@reach/router';
import { Footer } from '../Footer';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Row, Col } from '@perfocal/UIKit';

interface LayoutProps extends RouteComponentProps {
  children?: React.ReactNode;
  booking: BookingFormContext;
}
interface LayoutState {
  loading: boolean;
}
export class Layout extends React.Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);
    const state: LayoutState = {
      loading: true,
    };
    this.state = state;

    if (props.booking) {
      props.booking.turnOffLoader = this.toggleLoader.bind(this);
    }
  }

  onAction() {

  }

  onValue() {

  }

  toggleLoader() {
    this.setState({ loading: false });
  }

  render() {
    return (
      <>
        <Header />
        <Row>
          <Col className='text-center'>
            {this.state.loading ?
              <Loader
                type="ThreeDots"
                height={525}
                width={100}
                color="#535AE0"
              />
              :
              null
            }
          </Col>
        </Row>
        <main>{this.props.children}</main>
        <Footer ref={Footer => {window.Footer = Footer}} booking={this.props.booking}/>
      </>
    );
  }
}
