import React from 'react';
import { Col, Grid, Row } from '@perfocal/UIKit';

export function Header() {
  return (
    <div className="nav-container">
      <nav className="bar bar-1 bar--sm bar--mobile-sticky bar--absolute">
        <Grid style={{ position: 'relative' }}>
          <Row>
            <Col md={12}>
              <a target='_blank' href={`https://www.${process.env.DOMAIN}`}>
                <img
                  alt="logo"
                  className="logo logo-dark"
                  src="https://dkk5oy4zs0vc4.cloudfront.net/logo-dark.svg"
                />
                <img
                  alt="logo"
                  className="logo logo-light"
                  src="https://dkk5oy4zs0vc4.cloudfront.net/logo-light.svg"
                />
              </a>
              <div className="logo-motto hidden-xs">
                Everyone's perfect photographer.
              </div>
            </Col>
          </Row>
        </Grid>
      </nav>
        <Row className="hidden-xs">
          <p style={{ textAlign: 'center', marginTop: '5rem' }}>
            <a
              target='_blank'
              href="https://uk.trustpilot.com/review/perfocal.com"
              style={{ textDecoration: 'none' }}
            >
              <img
                src="https://emailsignature.trustpilot.com/newsletter/en-GB/1/598b39330000ff0005a87f3c/text1@2x.png"
                height={18}
                alt="Human score"
                style={{ maxHeight: '18px' }}
              />
            </a>{' '}
            &nbsp;&nbsp;
            <a
              target='_blank'
              href="https://uk.trustpilot.com/review/perfocal.com"
              style={{ textDecoration: 'none' }}
            >
              <img
                src="https://emailsignature.trustpilot.com/newsletter/en-GB/1/598b39330000ff0005a87f3c/stars@2x.png"
                height={20}
                alt="Trustpilot Stars"
                style={{ maxHeight: '20px' }}
              />
            </a>{' '}
            &nbsp;&nbsp;
            <span style={{ display: 'inline-block' }}>
              <a
                target='_blank'
                href="https://uk.trustpilot.com/review/perfocal.com"
                style={{ textDecoration: 'none' }}
              >
                <img
                  src="https://emailsignature.trustpilot.com/newsletter/en-GB/1/598b39330000ff0005a87f3c/text2@2x.png"
                  height={18}
                  alt="number of reviews"
                  style={{ maxHeight: '18px' }}
                />
              </a>{' '}
              &nbsp;&nbsp;
              <a
                target='_blank'
                href="https://uk.trustpilot.com/review/perfocal.com"
                style={{ textDecoration: 'none' }}
              >
                <img
                  src="https://emailsignature.trustpilot.com/brand/n/1/logo.png"
                  height={20}
                  alt="Trustpilot Logo"
                  style={{ maxHeight: '20px', display: 'inline-block' }}
                />
              </a>
            </span>
          </p>
        </Row>
    </div>
  );
}
