import React from 'react';
import { Row, Col, FormControlContext } from '@perfocal/UIKit';
import { SegmentResource } from "../../resources/segment.resource";
import PhoneInput from 'react-phone-number-input';
import { ShootResource } from '../../resources/shoot.resource';

interface FooterState {
  askedHelp: boolean;
  callBack: boolean;
  callBackSuccess: boolean;
  intercom: boolean;
  phone: string;
}

interface FooterProps {
  booking: any;
}

export class Footer extends React.Component<FooterProps, FooterState> implements FormControlContext{
  segmentResource: SegmentResource;
  shootResource: ShootResource;

  constructor(props: FooterProps) {
    super(props);
    this.state = {
      askedHelp: false,
      callBack: false,
      callBackSuccess: false,
      intercom: false,
      phone: ''
    }
    this.segmentResource = new SegmentResource();
    this.shootResource = new ShootResource();
  }

  componentDidMount() {
    const hideIntercom = document.getElementById('hide')
    if(hideIntercom) {
      window.setTimeout(() => {
        hideIntercom.click()
      },750)
    }
  }

  async onValue(field: string, value: any, isValid: boolean) {

  }

  async onAction(action: string) {
    switch (action) {
      case "shutdownIntercom" :
        const hideIntercom = document.getElementById('hide')
        if(hideIntercom) {
          hideIntercom.click()
        }
        this.setState({intercom: false})
      break;
      case "intercom" :
        this.setState({intercom: true})

        const loadIntercom = document.getElementById('load')
        if(loadIntercom) {
          loadIntercom.click()
        }

        const showIntercom = document.getElementById('show')
        if(showIntercom) {
          showIntercom.click()
        }

        const onHideIntercom = document.getElementById('onHide')
        if(onHideIntercom) {
          onHideIntercom.click()
        }
      break;
      case 'request-callback':
        try {

          const payload = { 
            phone: this.state.phone,
            scene_name: null as string,
            package_name: null as string,
            first_name: null as string,
            last_name: null as string,
            email: null as string
          }
          this.props.booking.shoot.package.name ? payload.package_name = this.props.booking.shoot.package.name : null;
          this.props.booking.shoot.scene.name ? payload.scene_name = this.props.booking.shoot.scene.name : null;
          this.props.booking.shoot.user.first_name? payload.first_name = this.props.booking.shoot.user.first_name : null;
          this.props.booking.shoot.user.last_name ? payload.last_name = this.props.booking.shoot.user.last_name : null;
          this.props.booking.shoot.user.email ? payload.email = this.props.booking.shoot.user.email : null;

          await this.shootResource.requestCallback(payload)

          this.setState({callBackSuccess: true})
          window.setTimeout(() => { this.setState({ callBackSuccess: false, callBack: false, askedHelp: false, phone: ''})}, 5000)

        } catch (error) {
          console.log(error)
        }
      break;
    }
  }

  render() {

    return (
      <>
        <section className="text-center unpad">
          <div className="container mt--1">
            <Row className="hidden-xs">
              <div className="col-sm-12 col-sm-offset-1">
                <div className="col-xs-4 col-md-4">
                  <div className="feature feature-1">
                    <i className="icon icon--lg icon-Camera color--primary"></i>
                    <div className="feature__body">
                      <h4 className="color--primary">Unlimited photos</h4>
                      <div>
                        We don't like <strong>limits</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-4 col-md-4">
                  <div className="feature feature-1">
                    <i className="icon icon--lg icon-Hour color--primary"></i>
                    <div className="feature__body">
                      <h4 className="color--primary">Full flexibility</h4>
                      <div>
                        <strong>Cancel</strong> up to 24 hours before the shoot
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-4 col-md-4">
                  <div className="feature feature-1">
                    <i className="icon icon--lg icon-Medal-3 color--primary"></i>
                    <div className="feature__body">
                      <h4 className="color--primary">1000+ happy customers</h4>
                      <div>
                        <strong>Excellent</strong> ratings on Trustpilot.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </section>
        <footer className="footer-4 space--xxs mb--1 text-center">
          <div className="container">
            <Row>
              <Col xs={12}>
                {!this.state.intercom ?
                this.state.callBackSuccess ?
                 <div className='chat' onClick={() => {this.setState({askedHelp: !this.state.askedHelp})}}>
                 Thanks! We'll be in touch very soon 😊
                 </div>
                :
                <div>
                  <div className='chat' onClick={() => {this.setState({askedHelp: !this.state.askedHelp, callBackSuccess: false, callBack: false}); this.segmentResource.segmentTrack("Perfocal Help Bubble - Expanded",{})}}>
                  👋 Any Questions ?
                  </div>
                  {this.state.askedHelp ? 
                    <div>
                      {this.state.callBack ?
                        <div className='chat-option-3' onClick={() => { this.onAction('request-callback'); this.segmentResource.segmentTrack("Perfocal Help Bubble - Callback Number Submitted",{}) }} >
                         Submit
                        </div>
                        :
                        <div className='chat-option-3' onClick={() => {this.setState({callBack: !this.state.callBack}); this.segmentResource.segmentTrack("Perfocal Help Bubble - Callback Clicked",{})}} >
                          Request a callback
                        </div>
                      }
                      {this.state.callBack ?
                        <div className='chat-option-4'>
                          <PhoneInput
                            required
                            international
                            defaultCountry='GB'
                            value={this.state.phone? '+' + this.state.phone : null }
                            maxLength={16}
                            onChange={(value: string) => {
                              const phone = value ? value.replace('+', '') : value
                              this.setState({phone: phone})
                              this.onValue('phone', phone, true);
                            }}
                          />
                        </div>
                      : null}
                      {!this.state.callBack ?
                      <div>
                        <div className='chat-option-1'>
                          <a href="javascript:Intercom('showNewMessage')" onClick={() => { this.onAction('intercom'); this.segmentResource.segmentTrack("Perfocal Help Bubble - Chat Opened",{})}}>
                          Chat to us
                          </a>
                        </div>
                        <div className='chat-option-2'>
                          <a href="tel:+442080898087" onClick={() => {this.segmentResource.segmentTrack("Perfocal Help Bubble - Call Perfocal",{})}}>Speak to a specialist</a>
                        </div>
                      </div>
                      : null}
                    </div>
                  : null}
                </div>
                
                : null}
                <div>
                  <img
                    alt="Perfocal"
                    className="logo logo-dark"
                    src="https://dkk5oy4zs0vc4.cloudfront.net/iconmark-dark.svg"
                    style={{ maxHeight: 32 }}
                  />
                </div>
                <div>
                  <span className="type--fine-print">
                    ©{' '}
                    <span className="update-year">
                      {new Date().getFullYear()}
                    </span>{' '}
                    Perfocal Limited.
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <a id='hide' href="javascript:Intercom('shutdown')" />
          <a id='load' href={`javascript:Intercom('boot', {  
                            app_id: '${process.env.INTERCOM_ID}',  
                            })`} 
          />
          <a id='show' href="javascript:Intercom('showNewMessage')" />
          <a id='onHide' href={`javascript:Intercom('onHide', function() { 
            Intercom('shutdown');
            if(typeof window != 'undefined'){
              window.Footer.setState({intercom: false, askedHelp: false})
            };
           })`} />
        </footer>
      </>
    );
  }
}
