/**
 * Generic Resource wrapper class.
 * Contains common methods for accessing and managing DB data and APIs
 */
export default class Resource {
  _baseURI: string;
  constructor(pathname: string) {
    this._baseURI = `https://www.${process.env.DOMAIN}/api${pathname}`;
  }

}