import * as request from "superagent";
import Resource from "./_base.resource";
import Shoot from "../models/shoot.model";
import { CreateShootDto } from "./dto/shoot/create-shoot.dto";
import { RequestCallbackDto } from "./dto/shoot/request-callback.dto";


export class ShootResource extends Resource {
  constructor() {
    super("/shoots");
  }

  /**
   * Process a free checkout of an event
   * @param id ID of the event to process
   * @param promocode Optional - Promocode to check validity on
   * @returns Event body
   */
  async freeCheckout(id: string | number, promocode: string, payment_intent: string, payment_intent_secret: string): Promise<any> {
    const payload = {
      shoot_id: id,
      payment_intent_id: payment_intent,
      payment_intent_secret: payment_intent_secret,
      promocode: promocode
    }
    const req = request
      .post(`${this._baseURI}/pay`)
      .set("Accept", "application/json")
      .set("Content-type", "application/json")

    const response = await req.send(payload);
    return response.body as any;
  }

  async create(payload: CreateShootDto): Promise<any> {
    const req = request
    .post(`${this._baseURI}`)
    .set("Accept", "application/json")
    .set("Content-type", "application/json");

    const response = await req.send(payload);
    return response.body;
  }

  async requestCallback(payload: RequestCallbackDto): Promise<any> {
    const req = request
    .post(`${this._baseURI}/request-callback`)
    .set("Accept", "application/json")
    .set("Content-type", "application/json");

    await req.send(payload);
    return 'ok'
  }

  async getUnpaidShoot(shoot_id: string): Promise<any> {
    const req = request
      .get(`${this._baseURI}/unpaid/${shoot_id}`)
      .set("Accept", "application/json")
      .set("Content-type", "application/json");

    const response = await req.send();
    return response.body;
  }

}